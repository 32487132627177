import React, { useState, useEffect } from "react";
import { Row, Col, Button, Image } from "reactstrap";
import Layout from "../../components/Layout";
import RecentNews from "../../components/RecentNews/RecentNews";
import BuildingGuideModal from "../../components/Modals/BuildingGuideModal/BuildingGuideModal";
import ConfirmationModal from "../../components/Modals/ConfirmationModal/ConfirmationModal";
import { setModal } from "../../reducers/Home/actions";
import { getGuides } from "../../reducers/BuildingGuide/actions";
import { connect } from "react-redux";
import { graphql } from "gatsby";
import { urlDescription } from "../../utils/pageDescription";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import promotionImg1 from "../../assets/images/guides-step-1.svg";
import promotionImg2 from "../../assets/images/guides-step-2.svg";
import promotionImg3 from "../../assets/images/promotions-step-3.svg";
import horizontalArrow from "../../assets/images/horizontal-arrow.svg";
import verticalArrow from "../../assets/images/vertical-arrow.svg";
import downloadIcon from "../../assets/images/download-image.svg";
import "./styles.scss";

const BuildingGuidePage = ({
  data,
  guides,
  page,
  successModal,
  getGuides,
  setModal,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(null);

  const handleResize = () => {
    if (typeof window !== "undefined" && window.innerWidth < 1051) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getGuides({ page });
  }, [getGuides, page]);

  const staticGuides = data.allStrapiBuildingguides.edges;
  const staticLastProject = data && data.lastProjects.edges;
  const staticLastNew = data && data.lastNews.edges;
  const staticLastArticle = data && data.lastArticles.edges;

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  const imageUrl = getImage(data.guideImage.childImageSharp);

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="building-guide-wrapper">
        <div className="building-guide-container">
          <div className="building-guide-title">
            <h1 className="h2 font-weight-bold">Строй лесно!</h1>
            <p>Твоят пътеводител за строеж на къща</p>
          </div>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex justify-content-center">
              <GatsbyImage alt="Book image" image={imageUrl} />
            </Col>
            <Col xs={10} className="py-5">
              <p>
                Пътеводителят „СТРОЙ ЛЕСНО!“ ще Ви преведе през целия строителен
                процес – от закупуването на парцел до нанасянето в завършената
                къща. Разгърнете страниците му, ако искате да знаете какво точно
                се случва на строителната площадка и да откриете ценни
                практически насоки и съвети за строеж на еднофамилна къща! По
                създаването на това издание е работила група от опитни експерти,
                поставила си за цел да Ви преведе през целия сложен процес на
                строителството на дом. Можете да се съветвате с нашия
                пътеводител по всяко време. Освен отговори на конкретни въпроси
                в него ще намерите речник, практически указания и полезни
                връзки, за да задълбочите познанията си по разгледаните теми.
              </p>
            </Col>
            <Col xs={12} className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  setId(staticGuides[0].node.id);
                  setModalOpen(!isModalOpen);
                }}
                className="guide-download-button d-flex py-2 px-4"
              >
                <img
                  className="pl-2 pr-3"
                  src={downloadIcon}
                  alt="download-icon"
                />
                ИЗТЕГЛИ БЕЗПЛАТНО
              </Button>
            </Col>
          </Row>
        </div>

        <div className="guides-steps-wrapper">
          <div className="guides-steps-container px-3">
            <div className="mt-3 guides-page-info">
              <h2 className="font-weight-bold">Как да се възползвам</h2>
              <p>
                Попълнете Вашите данни в регистрационната форма и бързо и лесно
                свалете строителния наръчник в PDF формат. Отнема по-малко от 2
                минути.
              </p>
            </div>
            <Row className="pb-2 mb-5 discounts-info-row">
              <Col className="discounts-info-row__col">
                <h5 className="font-weight-bold">Стъпка 1</h5>
                <img
                  className="guide-step-img"
                  alt="step-1"
                  src={promotionImg1}
                />
                <p className="discounts-info-desc">
                  Натиснете бутон "ИЗТЕГЛИ БЕЗПЛАТНО"
                </p>
              </Col>
              <Col
                sm={isMobile ? 12 : ""}
                className="discounts-info-row__arrow-col mb-xs-4 mb-md-4"
              >
                <img
                  alt="arrow"
                  className="discounts-arrow"
                  src={isMobile ? verticalArrow : horizontalArrow}
                />
              </Col>
              <Col className="discounts-info-row__col">
                <h5 className="font-weight-bold">Стъпка 2</h5>
                <img
                  className="guide-step-img"
                  alt="step-2"
                  src={promotionImg2}
                />
                <p className="discounts-info-desc">
                  Попълнете данните във формата за регистрация вкл. Вашия имейл
                  адрес
                </p>
              </Col>
              <Col
                sm={isMobile ? 12 : ""}
                className="discounts-info-row__arrow-col mb-xs-4 mb-md-4"
              >
                <img
                  alt="arrow"
                  className="discounts-arrow"
                  src={isMobile ? verticalArrow : horizontalArrow}
                />
              </Col>
              <Col className="discounts-info-row__col">
                <h5 className="font-weight-bold">Стъпка 3</h5>
                <img alt="step-3" src={promotionImg3} />
                <p className="discounts-info-desc">
                  Ще получите пътеводителя на посочения от Вас имейл
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <RecentNews
        title="Още полезна информация..."
        staticLastProject={staticLastProject}
        staticLastNew={staticLastNew}
        staticLastArticle={staticLastArticle}
      />
      <BuildingGuideModal
        guide
        title="Попълнете формата и свалете брошурата"
        isOpen={isModalOpen}
        id={id}
        onClick={() => {
          setModalOpen(!isModalOpen);
        }}
      />
      <ConfirmationModal
        title={`Брошурата е изпратена на посочения имейл.`}
        openFromGuides={true}
        isOpen={successModal}
        onClick={() => setModal()}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    guides: state.guides.data,
    page: state.guides.current_page,
    successModal: state.home.successModal,
  };
};

const mapDispatchToProps = {
  getGuides,
  setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingGuidePage);

export const query = graphql`
  {
    guideImage: file(relativePath: { eq: "e-book-cover.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
      }
    }
    allStrapiBuildingguides {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    lastArticles: allStrapiArticleslast {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    lastNews: allStrapiNewslast {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
    lastProjects: allStrapiProjectslast {
      edges {
        node {
          id: strapiId
          title
          author_name
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
